import "./Pending.scss";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { createSale } from "@services/deals.service";
import Loader from "@components/common/Loader/loading";
import { QueryRequestHelper } from "common/query-request-helper";
import _ from "lodash";
import { toast } from "sonner";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import SimpleReactValidator from "simple-react-validator";
import {
  createContact,
  getSpecificContact,
  updateContact,
} from "@services/contacts.service";
import { createCompany } from "@services/companies.service";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import {
  associationSubPipeline,
  disAssociationSubPipeline,
} from "@services/common.service";
import { useStateContext } from "context/profileProvider";
import {
  FormRequestHelper,
  appendObjectValuesToArray,
} from "@components/helpers/request-helper";
import ButtonComponent from "@components/Form/Button/Button";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { motion } from "framer-motion";
import { getAllModuleFields } from "@services/module-fields.service";
import DatePicker from "react-datepicker";
import CheckCircleSvgComponent from "@assets/svg/check-circle";
import DateOnHover from "@components/common/Tooltip/date-on-hover";
import { convertDateTime, getDate } from "@common/date-helpers";
import moment from "moment";
import { createTask } from "@services/tasks.service";
import CommonModuleDetailPage from "./main/CommonModuleDetail";
import ListLoader from "@components/common/Loader";
import { LoginOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPipelineDetailsProps {}

const ContactPipelineDetails: React.FunctionComponent<IPipelineDetailsProps> = (
  props: any
) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    getData(true);
  }, []);
  const { userId, selectedModuleId, allModules } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [data, setData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const getData = async (loading: boolean) => {
    try {
      setIsLoading(loading);
      let response = await getSpecificContact(getValue(params, `id`, ""));
      if (response) {
        let resp = await getSpecificPipeline(
          selectedModuleId(),
          getValue(response, `data.pipeline_id`, "")
        );
        setStageList(
          getValue(resp, `data.stages`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `label`, ""),
            value: getValue(item, `api_name`, ""),
          }))
        );

        let fields =
          getValue(resp, `data.form_fields.length`, 0) > 0
            ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                ...getValue(item, `module_field`, {}),
                name: getValue(item, `name`, ""),
                quick_add: getValue(item, `quick_add`, ""),
                required: getValue(item, `required`, ""),
                seq_num: getValue(item, `seq_num`, ""),
              }))
            : [];
        // ---------------- Appending Fields Data ----------------- //
        let array: any = appendObjectValuesToArray(
          fields,
          getValue(response, `data`, {})
        );
        let fieldReq =
          getValue(array, `length`, 0) > 0
            ? array.filter(
                (item: object) =>
                  getValue(item, `api_name`, "") !== "first_name" &&
                  getValue(item, `api_name`, "") !== "last_name" &&
                  getValue(item, `api_name`, "") !== "email"
              )
            : [];
        let fieldPopReq =
          getValue(array, `length`, 0) > 0
            ? array.filter(
                (item: object) =>
                  getValue(item, `api_name`, "") === "first_name" ||
                  getValue(item, `api_name`, "") === "last_name" ||
                  getValue(item, `api_name`, "") === "email"
              )
            : [];
        setFields(fieldReq);
        setDirtyFields(fieldReq);
        setPopupFields(fieldPopReq);
        setDirtyPopupFields(fieldPopReq);
        // ---------------- Appending State Data ----------------- //

        setData(getValue(response, `data`, {}));
        let leftReq = {
          first_name: getValue(response, `data.first_name`, ""),
          last_name: getValue(response, `data.last_name`, ""),
          email: getValue(response, `data.email`, ""),
          stage: getValue(response, `data.pipeline_stage.id`, ""),
        };
        setLeftRequest({
          ...leftRequest,
          ...leftReq,
        });
        setDirtyLeftRequest({
          ...dirtyLeftRequest,
          ...leftReq,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const urlParamsFun = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    let request = QueryRequestHelper({ ...urlParams });
    return request;
  };

  const mainQueryRequest = React.useMemo(() => urlParamsFun(), [
    window.location.href,
  ]);

  /* -------------------------------------------------------------------------- */
  /*                               Left Section                                 */
  /* -------------------------------------------------------------------------- */

  const [leftRequest, setLeftRequest] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    stage: "",
  });
  const [dirtyLeftRequest, setDirtyLeftRequest] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    stage: "",
  });
  const [fields, setFields] = React.useState([]);
  const [dirtyFields, setDirtyFields] = React.useState([]);

  const [popupFields, setPopupFields] = React.useState([]);
  const [dirtyPopupFields, setDirtyPopupFields] = React.useState([]);

  let checkLeftRequestChanges = _.isEqual(leftRequest, dirtyLeftRequest);
  let checkfieldRequestChanges = _.isEqual(fields, dirtyFields);

  /* -------------------------------------------------------------------------- */
  /*                               Middle Section                               */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Right Section                                */
  /* -------------------------------------------------------------------------- */

  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  const [selectedPipeline, setSelectedPipeline] = React.useState("");
  const [form, setForm] = React.useState([]);
  const [formLoading, setFormLoading] = React.useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false);
  const [formStageList, setFormStageList] = React.useState<any>([]);

  const getForm = async (module: string) => {
    setAssociationForm([]);
    if (module !== "tasks" && module !== "meetings" && module !== "calls") {
      try {
        let payload = {
          module_name: module,
        };
        let queryRequest = QueryRequestHelper(payload);
        setFormLoading(true);
        let findId =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) =>
                  getValue(item, `api_name`, "") ===
                  (module === "sales" ? "deals" : module)
              )
            : [];
        let resp = await allPipelines(getValue(findId, `id`, ""), queryRequest);
        if (resp) {
          getAssociationPipelineInfo(
            getValue(resp, `data[${0}].id`, ""),
            module
          );
          setFormStageList(
            getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `api_name`, ""),
            }))
          );
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {
        setFormLoading(false);
      }
    } else {
      try {
        let code =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) => getValue(item, `api_name`, "") == module
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `module_field.api_name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setAssociationForm(fields);
        }
      } catch (error) {}
    }
  };
  const [associationForm, setAssociationForm] = React.useState([]);
  const getAssociationPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            stage: getValue(resp, `data.stages[${0}].id`, ""),
            pipeline_id: id,
          }));
          setAssociationForm(fields);
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };

  /* ---------------------------  Pipeline By ID  -------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [stageList, setStageList] = React.useState<any>([]);
  const getSpecificPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setForm(fields);
          if (module !== "tasks") {
            setStageList(
              getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                label: getValue(item, `label`, ""),
                value: getValue(item, `name`, ""),
              }))
            );
          }
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };
  /* ---------------------------  Association / DisAssociate Pipeline  --------------------- */

  const handleAssociate = async (id: string, module: string) => {
    if (module) {
      let payload = {
        api_name: module,
        record_id: id,
      };
      let resp = await associationSubPipeline(
        "contacts",
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        getData(false);
        toast.success("Associated successfully");
      }
    }
  };
  const handleDisAssociate = async (id: string, module: string) => {
    if (module) {
      let payload = {
        api_name: module,
        record_id: id,
      };
      let resp = await disAssociationSubPipeline(
        "contacts",
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        getData(false);
        toast.success("Disassociated successfully");
      }
    }
  };

  const handleCreateForm = async (module: string) => {
    if (module) {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      } else {
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["pipeline_id"] = selectedPipeline;
        requestPayload["sale_id"] = getValue(params, `id`, "");
        try {
          setFormSubmitLoading(true);
          let resp;
          if (module === "contacts") {
            resp = await createContact(requestPayload);
          }
          if (module === "companies") {
            resp = await createCompany(requestPayload);
          }
          if (module === "sales" || module === "deals") {
            resp = await createSale(requestPayload);
          }
          if (resp) {
            toast.success("Created & Associated Successfully");
            setFormSubmitLoading(false);
            getData(false);
            simpleValidator.current.hideMessages();
            forceUpdate(0);
          } else {
            setFormSubmitLoading(false);
          }
        } catch (error) {
          setFormSubmitLoading(false);
        }
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Footer Section                               */
  /* -------------------------------------------------------------------------- */
  const handleReset = async () => {
    setLeftRequest(dirtyLeftRequest);
    setFields(dirtyFields);
    setDuplicateAPINames([]);
  };

  const handleCancel = () => {
    handleReset();
  };
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const handleSubmit = async () => {
    try {
      let payload: any = {
        first_name: getValue(leftRequest, `first_name`, ""),
        last_name: getValue(leftRequest, `last_name`, ""),
        email: getValue(leftRequest, `email`, ""),
        pipeline_stage_id: getValue(leftRequest, `stage`, ""),
      };
      let fieldReq = JSON.parse(JSON.stringify(fields));
      let payloadNew = FormRequestHelper(fieldReq);
      let requestPayload: any = { ...payload, ...payloadNew };
      setSubmitLoading(true);
      let resp = await updateContact(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        toast.success("Updated successfully");
        getData(false);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           Quick Task Section                               */
  /* -------------------------------------------------------------------------- */
  const [closePopup, setClosePopup] = React.useState(false);
  const [taskRequest, setTaskRequest] = React.useState<any>({
    owner_id: userId,
    title: "",
    priority: "",
    due_datetime: null,
    reminder_datetime: null,
  });

  const handleSubmitTask = async () => {
    let payload = {
      task: taskRequest,
      associations: [
        { api_name: "contacts", record_id: getValue(params, `id`, "") },
      ],
    };
    let resp = await createTask(payload);
    if (resp) {
      getData(false);
      setClosePopup(true);
      toast.success("Created follow up task");
      setTaskRequest({
        ...taskRequest,
        owner_id: userId,
        title: "",
        priority: "",
        due_datetime: null,
        reminder_datetime: null,
      });
      let payload = {
        ...UrlParams,
        activeTab: 2,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  const [duplicateAPINames, setDuplicateAPINames] = React.useState<any>([]);
  return (
    <MainLayout {...props} isLoading={isLoading}>
      {isLoading ? (
        <Loader />
      ) : (
        <CommonModuleDetailPage
          data={data}
          params={params}
          backUrl={`/${getValue(
            params,
            `orgId`,
            ""
          )}/crm/contacts?${mainQueryRequest}`}
          isChanged={!checkLeftRequestChanges || !checkfieldRequestChanges}
          request={leftRequest}
          setRequest={setLeftRequest}
          fields={fields}
          setFields={setFields}
          popupFields={popupFields}
          setPopupFields={setPopupFields}
          getData={getData}
          // ------------ Right Section Props ----------- //
          form={form}
          setForm={setForm}
          formLoading={formLoading}
          formSubmitLoading={formSubmitLoading}
          handleCreateForm={handleCreateForm}
          handleAssociate={handleAssociate}
          handleDisAssociate={handleDisAssociate}
          simpleValidator={simpleValidator}
          getForm={getForm}
          module={"contacts"}
          //static dropddowns
          // pipelineList={pipelineList}
          stageList={stageList}
          staticFieldRequest={staticFieldRequest}
          setStaticFieldRequest={setStaticFieldRequest}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          formStageList={formStageList}
          associationForm={associationForm}
          setAssociationForm={setAssociationForm}
          duplicateAPINames={duplicateAPINames}
          setDuplicateAPINames={setDuplicateAPINames}
          permissions={getValue(props, `permissions`, [])}
        />
      )}
      <div className="position-relative ">
        {/* <div className="fixed-task-container p-2 w-30 d-flex align-items-start">
          <div
            className={`form_inline_builder_field`}
            // style={{ width: props.width }}
          >
            <label className="form-label-inline">{"Followup Date& Time"}</label>
            <DatePicker
              className={`inline_form_input`}
              selected={new Date()}
              onChange={onChange}
              // selected={value ? moment(value).toDate() : null}
              // onChange={onChange}
              // placeholderText={placeholder}
              showTimeSelect
              //   showTimeSelectOnly
              timeIntervals={15}
              dateFormat="d MMM yyyy, p"
              timeCaption="Time"
            />
          </div>
          <div className="cursor-pointer mt-1">
            <CheckCircleSvgComponent />
          </div>
        </div> */}
        <div className="fixed-task-button">
          <DateOnHover
            header={
              <button className="dashboard-button dashboard-button--smart">
                Set Follow Up Date
              </button>
            }
            hover
            width={"34%"}
            popoverVisible={closePopup}
            setPopoverVisible={setClosePopup}
            render={
              <div className=" d-flex align-items-start">
                <div
                  className={`form_inline_builder_field w-100`}
                  // style={{ width: props.width }}
                >
                  <label className="form-label-inline">
                    {"Followup Date & Time"}
                  </label>
                  <DatePicker
                    className={`inline_form_input`}
                    onChange={(e: any) =>
                      setTaskRequest({
                        ...taskRequest,
                        title: "Follow up task",
                        priority: "low",
                        due_datetime: convertDateTime(e),
                        reminder_datetime: convertDateTime(e),
                      })
                    }
                    selected={
                      getValue(taskRequest, `due_datetime`, "")
                        ? getDate(getValue(taskRequest, `due_datetime`, ""))
                        : null
                    }
                    showTimeSelect
                    showMonthDropdown
                    showYearDropdown
                    timeIntervals={15}
                    dateFormat="d MMM yyyy, p"
                    timeCaption="Time"
                    placeholderText="Choose Follow up task"
                  />
                </div>
                {getValue(props, `permissions`, []).includes("create") && (
                  <div
                    className="cursor-pointer mt-1"
                    onClick={handleSubmitTask}
                  >
                    {/* <CheckCircleSvgComponent /> */}
                    <Tooltip title="Submit">
                      <LoginOutlined style={{ fontSize: "18px" }} />
                    </Tooltip>
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
      {(!checkLeftRequestChanges || !checkfieldRequestChanges) && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          exit={{
            y: 50,
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
          }}
        >
          <div className={"footer_container"}>
            <div className={"button_container"}>
              <div className="d-flex align-items-center">
                <ButtonComponent
                  buttonType={"scecondary"}
                  buttonText={"Cancel"}
                  onClickHandler={() => handleCancel()}
                  // squre
                />

                {getValue(props, `permissions`, []).includes("update") && (
                  <ButtonComponent
                    buttonType={"primary"}
                    buttonText={submitLoading ? "Please wait..." : "Update"}
                    onClickHandler={() => handleSubmit()}
                    disabled={submitLoading || duplicateAPINames.length > 0}
                    // squre
                  />
                )}
              </div>
            </div>
            <p className={"text"}>You have unsaved changes</p>
          </div>
        </motion.div>
      )}
    </MainLayout>
  );
};

export default ContactPipelineDetails;
